.navbar {
  width: "100%" !important;
  padding: 1.5rem 1rem !important;
}

/* .form-inline {
  width: 100% !important;
} */

/* .search {
  border-width: 0px !important;
} */

.searchBar {
  padding: 0 !important;
  margin: 0 !important;
  border: 1px solid #bdc3c7;
  background-color: white;
  border-radius: 5px;
}

.noBorders {
  border: 0px !important;
}
