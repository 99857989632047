.sidebar {
  height: 100%;
  width: 30%;
  position: fixed;
  z-index: 1;
  top: 11.4%;
  left: 0;
  background-color: white;
  /* overflow-x: hidden; */
  transition: 0.5s;
  box-shadow: 0 4px 8px 0 rgba(80, 80, 80, 0.2);
}

.sidebar a {
  padding: 8px 8px 8px 15px;
  text-decoration: none;
  font-size: 16px;
  color: #000;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #000;
  background-color: #c0c0c0;
  text-decoration: none;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #ffffff;
  color: #000;
  padding: 8px 8px 8px 65px;
  border: none;
  font-weight: bold;
}

#main {
  transition: margin-left 0.5s;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}
